<template>
    <div class="form-dialog">
        <el-dialog
            v-model="isShow"
            :title="title"
            :close-on-click-modal="closeable"
            :close-on-press-escape="closeable"
            :show-close="closeable"
            @closed="$emit('form-submit', formData)"
        >
            <div class="dialog-body">
                <slot />
            </div>
            <template #footer>
                <span v-if="editable" class="dialog-footer">
                    <el-button v-if="closeable" @click="isShow = false"> 取消</el-button>
                    <el-button
                        v-loading="submitLoading"
                        :disabled="isLoading||submitLoading"
                        type="primary"
                        @click="onSubmit"
                    > {{ submitText }} </el-button>
                </span>
                <span v-else-if="!hideSubmitBtn" class="dialog-footer">
                    <el-button type="primary" @click="onSubmit"> {{ submitText }} </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import {ref} from "vue";

defineEmits(["form-submit"]);
const props = defineProps({
    "title": String,
    "submitHandle": Function,
    "submitLoading": Boolean,
    "submitText": {
        "type": String,
        "default": "确定",
    },
    "editable": {
        "type": Boolean,
        "default": true,
    },
    "closeable": {
        "type": Boolean,
        "default": true,
    },
    "hideSubmitBtn": {
        "type": Boolean,
        "default": false,
    },
});

const isShow = ref(true);
const formData = ref(null);
const isLoading = ref(false);

async function onSubmit() {
    try {
        if (props["submitHandle"]) {
            isLoading.value = true;
            formData.value = await props["submitHandle"]();
        }
        isShow.value = false;
    } catch (e) {
        console.error(e);
    }
    isLoading.value = false;
}
function submit(data) {
    formData.value = data;
    isShow.value = false;
}
defineExpose({submit});

</script>

<script>
import {createVNode, render} from "vue";
import _ from "lodash";

const FormDialog = {
    install(app) {
        FormDialog._app = app;
        app.component("FormDialog", FormDialog);
    },
    createChild() {
        return {
            open(options = {}, appendTo = document.body) {
                return new Promise((resolve) => {
                    const container = document.createElement("div");
                    options.onFormSubmit = (data) => {
                        resolve(_.cloneDeep(data));
                        render(null, container);
                    };
                    const vnode = createVNode(this, options);
                    vnode.appContext = FormDialog._app?._context;
                    render(vnode, container);
                    appendTo.appendChild(container.firstElementChild);
                });
            },
        };
    },
};
export default FormDialog;
</script>
<style scoped>
.dialog-body {
    display: flex;
    flex-direction: column;
}
</style>